<template>
  <div>
    <director_app_bar />
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        :title="
          'รายงานตัว ณ สถานศึกษา ตำแหน่ง รองผู้อำนวยการ ครั้งที่ : ' +
            (period_colleges.period_college_times || '-') +
            ' ปี : ' +
            (period_colleges.period_college_year || '-')
        "
      >
        <v-card>
          <v-simple-table>
            <tbody>
              <tr>
                <td class="text-center">
                  รอบที่ {{ period_colleges.period_college_round }}
                </td>
                <td class="text-center">
                  ลำดับที่ {{ period_colleges.period_college_idstart }} -
                  {{ period_colleges.period_college_idend }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
        <v-card class="pa-1">
          <v-simple-table>
            <tbody>
              <tr>
                <td class="text-center">
                  <h2 class="font-weight-bold" color="info">
                    จำนาน :
                    {{ sedirector_appCount.sumAll }}
                    คน
                  </h2>
                </td>
                <td class="text-center">
                  <h2 class="font-weight-bold" color="info">
                    รายงานตัว :
                    {{ sedirector_appCount.countreceipt }}

                    คน
                  </h2>
                </td>

                <td class="text-center">
                  <h2 class="font-weight-bold" color="info">
                    สละสิทธิ์ :
                    {{ sedirector_appCount.countmissing }}

                    คน
                  </h2>
                </td>

                <td class="text-center">
                  <h2 class="font-weight-bold">
                    ยังไม่ได้ทำรายการ :

                    {{ sedirector_appCount.countnull }}

                    คน
                  </h2>
                </td>
              </tr>

              <tr>
                <td class="text-center" colspan="2">
                  <v-btn
                    rounded
                    color="info"
                    :href="
                      '#/admin/print_sedirector_app_score_report/?times=' +
                        period_colleges.period_college_times +
                        '&years=' +
                        period_colleges.period_college_year
                    "
                    target="_blank"
                    ><v-icon>mdi-printer</v-icon>ส่งออกข้อมูลผู้สมัคร
                  </v-btn>
                </td>
                <td class="text-center" colspan="2">
                  <v-btn rounded color="success" @click="noticeSedirector()"
                    ><v-icon>mdi-checkbox-marked-circle-outline</v-icon>ประกาศผล
                  </v-btn>
                </td>               
              </tr>
            </tbody>
          </v-simple-table></v-card
        >

        <v-select
          v-model="selectedHeaders"
          :items="headers"
          label="เลือกคอลัมน์ที่ต้องการแสดง"
          multiple
          outlined
          return-object
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 6">
              <span>{{ item.text }}</span>
            </v-chip>
            <span v-if="index === 6" class="grey--text caption"
              >(+{{ selectedHeaders.length - 6 }} คอลัมน์)</span
            >
          </template>
        </v-select>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
          single-line
          hide-details
          dense
          filled
          class="mb-2"
        />
        <v-data-table
          color="success"
          :loading="loading"
          :headers="showHeaders"
          :items="sedirector_apps"
          :search="search"
          item-key="sedirector_app_idref"
        >
          <template v-slot:item.actions="{ item }">
            <div v-if="item.sedirector_app_college_report === 'receipt'">
              <v-icon size="40" color="success">mdi-email-open</v-icon>
            </div>
            <div v-else-if="item.sedirector_app_college_report === 'missing'">
              <v-icon size="40" color="red">mdi-account-remove</v-icon>
            </div>
            <div v-else>
              <v-icon size="40" color="warning">mdi-email-alert</v-icon>
            </div>
          </template>

          <template v-slot:item.sedirector_app_college_filereport="{ item }">
            <div>
              <v-btn
                @click="viewReportPDF(item.sedirector_app_college_filereport)"
                v-if="item.sedirector_app_college_filereport"
                fab
                small
                color="info"
              >
                <v-icon>mdi-printer</v-icon>
              </v-btn>
            </div>
          </template>

          <template v-slot:item.sedirector_app_college_datetime="{ item }">
            <div>
              {{
                item.sedirector_app_college_datetime
                  | moment("add", "543 years")
                  | moment("D MMMM YYYY: HH:mm:ss a")
              }}
            </div>
          </template>

          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!-- V-model sedirector_app_pdfcollegeDialog -->
      <v-layout row justify-center>
        <v-dialog v-model="sedirector_app_pdfcollegeDialog" max-width="80%">
          <v-card class="" elevation="2">
            <embed
              :src="'/HrvecRegisDir/' + pdffiles"
              width="100%"
              height="700px"
            />
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>{{ snackbar.text }}</v-card-text>
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
import director_app_bar from "../../../components/admin/director/director_app_bar";

export default {
  data() {
    return {
      value: "2",
      loading: false,
      ApiKey: "HRvec2021",
      sedirector_app_idref: [],
      valid: true,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },

      sedirector_apps: [],

      search: "",
      pagination: {},
      selected: [],
      headers: [],
      selectedHeaders: [],
      headersMap: [
        { text: "จังหวัด", align: "center", value: "province_name" },
        {
          text: "รหัสบัตรประชาชน",
          align: "center",
          value: "sedirector_app_idcard"
        },
        { text: "ชื่อ-นามสกุล", align: "left", value: "fristnames" },
        /*       { text: "สถานศึกษาปัจจุบัน", align: "left", value: "user_firstname" }, */
        { text: "เลือก", align: "center", value: "actions" },

        {
          text: "เลือกวิทยาลัย",
          align: "center",
          value: "collegenameChoose"
        },
        {
          text: "เลขที่ตำแหน่ง",
          align: "center",
          value: "sedirector_app_collegeapp_idpositions"
        },
        {
          text: "ไฟล์แนบ",
          align: "center",
          value: "sedirector_app_college_filereport"
        },
        {
          text: "วันที่เวลา",
          align: "center",
          value: "sedirector_app_college_datetime"
        }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],

      sedirector_apps_id_ref: [],

      period_colleges: [],
      sedirector_appCount: {},
      rules: [
        value => !value || value.size < 5242880 || "ไฟล์ไม่เกิน 5 MB / 5120 KB",
        value => !!value || ""
      ],

      editedIndex: -1,
      editedItem: {
        sedirector_app_id: 0,
        statusAdd: ""
      },
      editedItemUpdate: {},
      defaultItem: {
        sedirector_app_id: 0,
        statusAdd: "add"
      },

      deletedItemmat: {},
      deletedItemmcm: {},
      sedirector_appsidrefs: [],
      director_college_manpowers: [],
      director_college_manpower_idpositions: [],
      update_director_college_manpowers: {},
      update_sedirector_app_notice: {},
      update_personnel_tem: {},
      pdffiles: [],
      sedirector_app_pdfcollegeDialog: false
    };
  },
  async created() {
    this.headers = Object.values(this.headersMap);
    this.selectedHeaders = this.headers;
  },

  async mounted() {
    await this.period_collegeQuery();
   
  },
  methods: {
    async viewReportPDF(sedirector_app_college_filereport) {
      this.pdffiles = sedirector_app_college_filereport;
      this.sedirector_app_pdfcollegeDialog = true;
    },

    async updatesedirector_apps(sedirector_app_idref) {
      let result_con = await this.$http.post("sedirector_app_dir.php", {
        ApiKey: this.ApiKey,
        sedirector_app_idref: sedirector_app_idref
      });
      this.sedirector_apps_id_ref = result_con.data;
      let text = this.sedirector_apps_id_ref.fristnames;
      Swal.fire({
        title: "รายการนี้สละสิทธิ์ ?",
        text: text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ขอยกเลิก",
        cancelButtonText: "ปิด"
      }).then(async result => {
        if (result.isConfirmed) {
          let result;
          this.sedirector_apps_id_ref.ApiKey = this.ApiKey;
          this.sedirector_apps_id_ref.sedirector_app_status_report = "disclaim";
          result = await this.$http.post(
            "sedirector_app.update.php",
            this.sedirector_apps_id_ref
          );
          if (result.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            this.sedirector_appQueryAll();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
      });
    },

    async noticeSedirector() {
      Swal.fire({
        title: "ประกาศผลการรายงานตัว ณ สถานศึกษา ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ประกาศ",
        cancelButtonText: "ปิด"
      }).then(async result => {
        if (result.isConfirmed) {
          let result;
          this.update_sedirector_app_notice.ApiKey = this.ApiKey;
          this.update_sedirector_app_notice.sedirector_app_year = this.period_colleges.period_college_year;
          this.update_sedirector_app_notice.sedirector_app_time = this.period_colleges.period_college_times;
          this.update_sedirector_app_notice.sedirector_app_collegeapp_status =
            "send";
          result = await this.$http.post(
            "sedirector_appApprove.update.php",
            this.update_sedirector_app_notice
          );

          if (result.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            this.sedirector_appQueryAll();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
      });
    },


    async period_collegeQuery() {
      let result_period_college;
      result_period_college = await this.$http.post(
        "period_college.php",
        {
          ApiKey: this.ApiKey,
          period_college_id: "40101"
        }
      );
      this.period_colleges = result_period_college.data;
      if(this.period_colleges.period_college_enable==='1'){
        await this.sedirector_appQueryAll();
      }
    },

    async sedirector_appQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("sedirector_app_dir.php", {
          ApiKey: this.ApiKey,
          sedirector_app_ptype: 3,
          sedirector_app_time: this.period_colleges.period_college_times,
          sedirector_app_year: this.period_colleges.period_college_year,
          sedirector_app_ipastatus: "guarantee",
        })
        .finally(() => (this.loading = false));
      this.sedirector_apps = result.data;

      let data = this.sedirector_apps;
      let sum = 0;
      let countreceipt = 0;
      let countmissing = 0;
      let countnull = 0;
      data.forEach(value => {
        let collegeApps = String(value.sedirector_app_collegeapp);
        if (collegeApps.length > 5) {
          sum += 1;
        }
        if (
          collegeApps.length > 5 &&
          value.sedirector_app_college_report == "receipt"
        ) {
          countreceipt += 1;
        } else if (
          collegeApps.length > 5 &&
          value.sedirector_app_college_report == "missing"
        ) {
          countmissing += 1;
        } else if (collegeApps.length > 5) {
          countnull += 1;
        }
      });
      this.sedirector_appCount.sumAll = sum;
      this.sedirector_appCount.countreceipt = countreceipt;
      this.sedirector_appCount.countmissing = countmissing;
      this.sedirector_appCount.countnull = countnull;
    },

    getColor(calories) {
      if (calories > 0) return "green";
      else return "";
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    time_stamp() {
      let time = Date.now();
      return time;
    },
    period_college_years() {
      let yyyy = this.period_colleges.period_college_year;
      return yyyy;
    },
    color() {
      return "lime darken-4";
    },
    showHeaders() {
      return this.headers.filter(s => this.selectedHeaders.includes(s));
    }
  },
  components: { director_app_bar }
};
</script>

<style scoped>
.orange {
  background-color: rgb(236, 194, 115);
}
</style>
