var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('director_app_bar'),_c('v-container',{attrs:{"fluid":""}},[_c('base-material-card',{attrs:{"icon":"mdi-clipboard-text","title":'รายงานตัว ณ สถานศึกษา ตำแหน่ง รองผู้อำนวยการ ครั้งที่ : ' +
          (_vm.period_colleges.period_college_times || '-') +
          ' ปี : ' +
          (_vm.period_colleges.period_college_year || '-')}},[_c('v-card',[_c('v-simple-table',[_c('tbody',[_c('tr',[_c('td',{staticClass:"text-center"},[_vm._v(" รอบที่ "+_vm._s(_vm.period_colleges.period_college_round)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" ลำดับที่ "+_vm._s(_vm.period_colleges.period_college_idstart)+" - "+_vm._s(_vm.period_colleges.period_college_idend)+" ")])])])])],1),_c('v-card',{staticClass:"pa-1"},[_c('v-simple-table',[_c('tbody',[_c('tr',[_c('td',{staticClass:"text-center"},[_c('h2',{staticClass:"font-weight-bold",attrs:{"color":"info"}},[_vm._v(" จำนาน : "+_vm._s(_vm.sedirector_appCount.sumAll)+" คน ")])]),_c('td',{staticClass:"text-center"},[_c('h2',{staticClass:"font-weight-bold",attrs:{"color":"info"}},[_vm._v(" รายงานตัว : "+_vm._s(_vm.sedirector_appCount.countreceipt)+" คน ")])]),_c('td',{staticClass:"text-center"},[_c('h2',{staticClass:"font-weight-bold",attrs:{"color":"info"}},[_vm._v(" สละสิทธิ์ : "+_vm._s(_vm.sedirector_appCount.countmissing)+" คน ")])]),_c('td',{staticClass:"text-center"},[_c('h2',{staticClass:"font-weight-bold"},[_vm._v(" ยังไม่ได้ทำรายการ : "+_vm._s(_vm.sedirector_appCount.countnull)+" คน ")])])]),_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"2"}},[_c('v-btn',{attrs:{"rounded":"","color":"info","href":'#/admin/print_sedirector_app_score_report/?times=' +
                      _vm.period_colleges.period_college_times +
                      '&years=' +
                      _vm.period_colleges.period_college_year,"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-printer")]),_vm._v("ส่งออกข้อมูลผู้สมัคร ")],1)],1),_c('td',{staticClass:"text-center",attrs:{"colspan":"2"}},[_c('v-btn',{attrs:{"rounded":"","color":"success"},on:{"click":function($event){return _vm.noticeSedirector()}}},[_c('v-icon',[_vm._v("mdi-checkbox-marked-circle-outline")]),_vm._v("ประกาศผล ")],1)],1)])])])],1),_c('v-select',{attrs:{"items":_vm.headers,"label":"เลือกคอลัมน์ที่ต้องการแสดง","multiple":"","outlined":"","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                      var item = ref.item;
                      var index = ref.index;
return [(index < 6)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.text))])]):_vm._e(),(index === 6)?_c('span',{staticClass:"grey--text caption"},[_vm._v("(+"+_vm._s(_vm.selectedHeaders.length - 6)+" คอลัมน์)")]):_vm._e()]}}]),model:{value:(_vm.selectedHeaders),callback:function ($$v) {_vm.selectedHeaders=$$v},expression:"selectedHeaders"}}),_c('v-text-field',{staticClass:"mb-2",attrs:{"append-icon":"mdi-magnify","label":"ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง","single-line":"","hide-details":"","dense":"","filled":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"color":"success","loading":_vm.loading,"headers":_vm.showHeaders,"items":_vm.sedirector_apps,"search":_vm.search,"item-key":"sedirector_app_idref"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
                      var item = ref.item;
return [(item.sedirector_app_college_report === 'receipt')?_c('div',[_c('v-icon',{attrs:{"size":"40","color":"success"}},[_vm._v("mdi-email-open")])],1):(item.sedirector_app_college_report === 'missing')?_c('div',[_c('v-icon',{attrs:{"size":"40","color":"red"}},[_vm._v("mdi-account-remove")])],1):_c('div',[_c('v-icon',{attrs:{"size":"40","color":"warning"}},[_vm._v("mdi-email-alert")])],1)]}},{key:"item.sedirector_app_college_filereport",fn:function(ref){
                      var item = ref.item;
return [_c('div',[(item.sedirector_app_college_filereport)?_c('v-btn',{attrs:{"fab":"","small":"","color":"info"},on:{"click":function($event){return _vm.viewReportPDF(item.sedirector_app_college_filereport)}}},[_c('v-icon',[_vm._v("mdi-printer")])],1):_vm._e()],1)]}},{key:"item.sedirector_app_college_datetime",fn:function(ref){
                      var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm._f("moment")(item.sedirector_app_college_datetime,"add", "543 years"),"D MMMM YYYY: HH:mm:ss a"))+" ")])]}}])},[_c('v-alert',{attrs:{"slot":"no-results","value":true,"color":"error","icon":"mdi-alert"},slot:"no-results"},[_vm._v("ไม่พบผลลัพธ์ \""+_vm._s(_vm.search)+"\" ที่คุณกำลังค้นหา.")])],1)],1),_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-dialog',{attrs:{"max-width":"80%"},model:{value:(_vm.sedirector_app_pdfcollegeDialog),callback:function ($$v) {_vm.sedirector_app_pdfcollegeDialog=$$v},expression:"sedirector_app_pdfcollegeDialog"}},[_c('v-card',{attrs:{"elevation":"2"}},[_c('embed',{attrs:{"src":'/HrvecRegisDir/' + _vm.pdffiles,"width":"100%","height":"700px"}})])],1)],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-snackbar',{attrs:{"top":"","timeout":_vm.snackbar.timeout,"color":_vm.snackbar.color},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar.show = false}}},'v-btn',attrs,false),[_vm._v("Close")])]}}]),model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(_vm._s(_vm.snackbar.icon))]),_c('v-card-text',[_vm._v(_vm._s(_vm.snackbar.text))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }